export default [
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/views/manage/ViewManageHome'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/configurations',
    name: 'manage-configurations',
    component: () => import('@/views/manage/ViewManageConfigurations'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/editions',
    name: 'manage-editions',
    component: () => import('@/views/manage/ViewManageEditions'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/edition/:editionKey/:view',
    name: 'manage-edition',
    component: () => import('@/views/manage/ViewManageEdition'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/edition/:editionKey/league/:leagueKey/:view',
    name: 'manage-edition-league',
    component: () => import('@/views/manage/ViewManageEdition'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/edition/:editionKey/league/:leagueKey/tournament/:tournamentKey/:view',
    name: 'manage-tournament',
    component: () => import('@/views/manage/ViewManageTournament'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/edition/:editionKey/league/:leagueKey/team/:teamKey',
    name: 'manage-edition-league-team',
    component: () => import('@/views/manage/ViewManageTeam'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/edition/:editionKey/league/:leagueKey/tournament/:tournamentKey/team/:teamKey',
    name: 'manage-edition-league-tournament-team',
    component: () => import('@/views/manage/ViewManageTeam'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/activity',
    name: 'activity',
    component: () => import('@/views/manage/ViewManageActivity'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/notifications',
    name: 'manage-notifications',
    component: () => import('@/views/manage/ViewManageNotifications'),
    meta: { requiresAuth: true, forManagers: true },
  },
]
