<template>
  <div
    class="rounded-2xl bg-white transition-all cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
    :class="{ 'saturate-0': team.status == 'canceled' }"
  >
    <div class="flex flex-col p-5 gap-2 h-full">
      <div class="flex items-center justify-between text-left">
        <div class="font-semibold flex-1">
          {{ tournamentName }}
          <div class="text-gray-500 text-sm">{{ sportName }}</div>
        </div>
        <div>
          <fw-icon-shield-check v-if="team.status === 'approved'" class="h-8 w-8 text-primary" />
          <fw-icon-exclamation-triangle v-else-if="team.status === 'submitted'" class="h-8 w-8 juc-text-color" />
          <fw-icon-exclamation-triangle v-else-if="team.status === 'denied'" class="h-8 w-8 text-red-600" />
        </div>
      </div>
      <div class="flex-1 flex flex-col items-center justify-start">
        <TeamAvatar :team="team" :with-shadow="true" class="my-4" />

        <div
          class="font-bold text-center border-2 rounded-full px-4 py-1 mb-4"
          :style="{
            color: team.color ?? '#777777',
            borderColor: team.color ?? '#777777',
          }"
        >
          <div class="truncate min-w-0 max-w-[9rem]">{{ team?.name ?? $t('noName') }}</div>
        </div>
        <div v-if="numberPlayers > 1" class="text-center tex-sm font-semibold text-gray-600">
          {{ numberPlayers }} colegas de equipa
        </div>

        <div class="flex items-center justify-center gap-2 mb-5 mt-2">
          <div v-for="(player, p) in firstFourPlayers" :key="'player_' + p">
            <fw-avatar v-if="users[player]" size="xs" :user="users[player]" />
          </div>
          <div v-if="playersExcess > 0" class="text-xs text-gray-500">+{{ playersExcess }}</div>
        </div>
        <div v-if="team.status === 'approved'" class="text-sm text-primary font-semibold">Aprovada</div>
        <div v-else-if="team.status === 'submitted'" class="text-sm juc-text-color font-semibold">
          Aguarda aprovação
        </div>
        <div v-else-if="team.status === 'denied'" class="text-sm text-red-600 font-semibold">Rejeitada</div>
        <div v-else-if="team.status === 'cancelled'" class="text-sm text-gray-500 font-semibold">Cancelada</div>
        <div v-else-if="team.status === 'draft_1'" class="text-sm text-gray-500 font-semibold">Rascunho</div>
        <div v-else-if="team.status === 'draft_2'" class="text-sm text-gray-500 font-semibold">Pendente</div>
        <div v-if="team.submitted_at" class="text-xs text-gray-500 mt-2">
          Submetida em {{ team.submitted_at | formatDateTime }}
        </div>
        <div class="flex-1"></div>
        <div v-if="isInvite && inviteStatus === 'invalidated'" class="text-sm text-red-600 font-semibold">
          Convite de participação invalidado
        </div>
        <div v-else-if="isInvite && inviteStatus === 'denied'" class="text-sm text-red-600 font-semibold">
          Convite de participação rejeitado
        </div>
        <div v-else-if="isInvite" class="text-sm text-yellow-600 font-semibold">Convite de participação pendente</div>
        <div v-else class="text-xs text-gray-500 mt-2">Criada em {{ team.created_at | formatDateTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamAvatar from '@/components/TeamAvatar.vue'
export default {
  name: 'CardTeamDetailed',
  components: {
    TeamAvatar,
  },
  props: {
    team: {
      type: Object,
    },
    users: {
      type: Object,
      default: () => ({}),
    },
    tournament: {
      type: Object,
    },
    sport: {
      type: Object,
    },
    isInvite: {
      type: Boolean,
      default: false,
    },
    inviteStatus: {
      type: String,
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    sportName() {
      return this.sport?.modality?.title[this.language]
    },
    tournamentName() {
      return this.tournament?.title[this.language]
    },
    numberPlayers() {
      return this.team?.players?.length ?? 0
    },
    firstFourPlayers() {
      return this.team?.players?.slice(0, 4) ?? []
    },
    playersExcess() {
      return this.team?.players?.length - 4 ?? 0
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "noName": "Name not defined"
    },
    "pt": {
      "noName": "Sem nome"
    }
  }

</i18n>
