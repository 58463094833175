<template>
  <fw-modal
    :active.sync="active"
    size="min"
    width="54rem"
    :can-cancel="false"
    inner-scroll
    custom-class="!max-h-[90vh]"
    @close="active = false"
  >
    <div ref="modal-terms-and-conditions" class="overflow-y-auto" @scroll="onScroll">
      <fw-panel title="Regulamento Geral sobre a Proteção de Dados">
        <p>
          Declaro que tomei conhecimento da "Política de Privacidade e de Proteção de Dados da UC", disponível
          <a href="http://www.uc.pt/protecao-de-dados/politica_de_privacidade" target="_blank" class="text-primary"
            >http://www.uc.pt/protecao-de-dados/politica_de_privacidade</a
          >. Mais fui informado/a que:
        </p>

        <ol class="list-inside list-decimal mt-2 space-y-2">
          <li>
            A finalidade da recolha dos meus dados pessoais pela Universidade de Coimbra (UC), no presente contexto,
            destina-se à inscrição na competição desportiva Jogos Universidade de Coimbra;
          </li>
          <li>
            A responsável pelo tratamento é a UC, através do Estádio Universitário de Coimbra, sito em Santa Clara,
            3040-256, Coimbra. A UC Framework, na qualidade de parceira desenvolvedora da plataforma UC Competitions,
            tem sede na Praça da Porta Férrea, 3004-531, Coimbra;
          </li>
          <li>
            Os dados pessoais solicitados pela UC, e por mim fornecidos, são: nome completo, número de estudante, número
            mecanográfico, data de nascimento, contacto telefónico, contacto de email, nacionalidade, tipo de documento
            de identificação e respetivo número, género e tipo de inscrição;
          </li>
          <li>
            O prazo de conservação de dados pessoais é de 1 ano, contados após a última inscrição;
          </li>
          <li>
            Comunicações, interconexões e fluxos transfronteiriços: A UC garante que o tratamento de dados pessoais
            esteja conforme os requisitos do Regulamento Geral de Proteção de Dados (RGPD) e salvaguarde os direitos do
            titular dos dados. Para prestar os serviços, a UC pode recorrer ao apoio técnico da UC Framework, mencionado
            no ponto 2, assegurando que este adote as medidas técnicas e organizativas adequadas. Em caso de necessidade
            de subcontratação de outros serviços, essa relação será regulada por contrato, de forma a garantir o
            cumprimento do RGPD e das diretrizes da UC.
          </li>
          <li>
            Os meus dados pessoais referentes a email e password poderão ser utilizados como credenciação na app
            (aplicação) utilizada pelo Estádio Universitário de Coimbra para os Jogos Universidade de Coimbra;
          </li>
          <li>
            Na qualidade de titular dos dados, em conformidade com a Lei, tenho o direito de acesso, o direito de
            retificação, o direito de apagamento, direito de limitação do tratamento, direito de portabilidade, direito
            de oposição e direito de não ficar sujeito a decisões automatizadas podendo estes ser exercidos a qualquer
            momento, por escrito, mediante comunicação por correio eletrónico para desporto@uc.pt;
          </li>
          <li>
            O Encarregado de Proteção de Dados da UC pode ser contactado por correio eletrónico, para epd@uc.pt. O
            Responsável pelo Tratamento dos dados pessoais garante o cumprimento do disposto no RGPD, bem como na demais
            legislação aplicável, obrigando-se a respeitar e a cumprir os direitos expressos no ponto 7., quando
            aplicáveis, nos termos legais, e a não colocar à disposição de terceiros os dados pessoais de forma
            nominativa, sem a minha autorização pessoal. Mais declaro, para os efeitos do RGPD, ter tomado pleno
            conhecimento e compreendido devidamente os direitos que me assistem relativamente aos meus dados pessoais e
            o teor completo da presente declaração, nomeadamente os fins a que esta se destina, bem como o presente
            tratamento de dados.
          </li>
        </ol>
      </fw-panel>

      <fw-panel title="Termos e Condições" class="mt-5">
        Nos termos e para os efeitos do disposto no 2.º do art.º 40.º da Lei n.º 5/2007, de 16 de janeiro (Lei de Bases
        da Atividade Física e do Desporto), asseguro, sob minha honra, que não tenho quaisquer contraindicações para a
        prática da atividade física/ desportiva que pretendo desenvolver.
      </fw-panel>
    </div>

    <template #footer>
      <fw-button type="link-muted" @click.native="decline()">{{ $t('decline') }}</fw-button>
      <fw-button type="primary" :disabled="!scrolledToBottom" @click.native="accept()">{{ $t('accept') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      active: false,
      scrolledToBottom: false,
    }
  },

  methods: {
    open() {
      this.active = true

      this.$nextTick(() => {
        const el = this.$refs['modal-terms-and-conditions']
        if (el.scrollHeight > el.clientHeight) this.scrolledToBottom = false
        else this.scrolledToBottom = true
      })
    },

    close() {
      this.active = false
    },

    accept() {
      this.$emit('accept')
      this.close()
    },

    decline() {
      this.$emit('decline')
      this.close()
    },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      console.log('scrollTop, clientHeight, scrollHeight :>> ', scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight) {
        this.scrolledToBottom = true
      }
    },
  },
}
</script>

<i18n lang="json">
	{
		"pt": {
			"accept": "Aceitar",
      "decline": "Rejeitar"
		},
		"en": {
      "accept": "Accept",
      "decline": "Decline"
		}
	}
</i18n>
